import React from "react";
import { Link } from "react-router-dom";
import {
  faSearch,
  faHeart,
  faShoppingCart,
  faUser,
  faLanguage,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";


const products = [
  {
    id: 1,
    name: "Product 1",
    frontImage:
      "https://cdn.pixabay.com/photo/2016/12/06/09/30/blank-1886001_640.png",
    backImage:
      "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTbGRe7Gwzr_YOtd_nFxSGfzM9H17HZo6RPrATeLBGIWdXWzvXPFNLy0c43QVsOH480jtc&usqp=CAU",
    details: "T-Shirt UNIVERSE",
    details1: "XS S M S XL L",
    details2: "ZUM WarenKorb hinzufugen",
  },

  {
    id: 2,
    name: "Product 1",
    frontImage:
      "https://cdn.pixabay.com/photo/2016/12/06/09/30/blank-1886001_640.png",
    backImage:
      "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTbGRe7Gwzr_YOtd_nFxSGfzM9H17HZo6RPrATeLBGIWdXWzvXPFNLy0c43QVsOH480jtc&usqp=CAU",
    details: "T-Shirt UNIVERSE",
    details1: "XS S M S XL L",
    details2: "ZUM WarenKorb hinzufugen",
  },

  {
    id: 3,
    name: "Product 1",
    frontImage:
      "https://cdn.pixabay.com/photo/2016/12/06/09/30/blank-1886001_640.png",
    backImage:
      "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTbGRe7Gwzr_YOtd_nFxSGfzM9H17HZo6RPrATeLBGIWdXWzvXPFNLy0c43QVsOH480jtc&usqp=CAU",
    details: "T-Shirt UNIVERSE",
    details1: "XS S M S XL L",
    details2: "ZUM WarenKorb hinzufugen",
  },

  {
    id: 4,
    name: "Product 1",
    frontImage:
      "https://cdn.pixabay.com/photo/2016/12/06/09/30/blank-1886001_640.png",
    backImage:
      "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTbGRe7Gwzr_YOtd_nFxSGfzM9H17HZo6RPrATeLBGIWdXWzvXPFNLy0c43QVsOH480jtc&usqp=CAU",
    details: "T-Shirt UNIVERSE",
    details1: "XS S M S XL L",
    details2: "ZUM WarenKorb hinzufugen",
  },
 

  // Add more products as needed...
];

const Suggested = () => {
  return (
    <>
 
    
    <div className="flex flex-wrap gap-y-40 p-8 mb-36">
      {products.map((product) => (
        <Link
          to="/details"
          key={product.id}
          className="relative w-72 h-72 perspective group"
        >
          <div className="absolute inset-0 w-72 transform transition-transform duration-700 flip-card">
            {/* Front Side */}
            <div className="absolute w-72 inset-0 flex items-center justify-center backface-hidden">
              <img
                src={product.frontImage}
                alt={product.name}
                className="w-full h-full object-cover"
              />
            </div>
            {/* Back Side */}
            <div className="absolute w-72 inset-0 flex flex-col items-center justify-center transform rotate-y-180 backface-hidden">
              <img
                src={product.backImage}
                alt={product.name}
                className="w-full h-full object-contain mb-2"
              />
            </div>
          </div>
          {/* Product Details */}
       
          <div className="opacity-0 mb-2 group-hover:opacity-100 transition-opacity duration-300 absolute -bottom-36 left-0 right-0  text-white p-4 text-center">
           
          <p className="text-sm  text-black p-2 text-center">
            PLANT BASED DYED GARMENT
          </p >
          <div className="flex justify-between p-2 items-center bg-[#B3C8CF]">
          <p >{product.details}</p>
            <p>   <FontAwesomeIcon icon={faHeart} /></p>
          </div>
          
            <p className="flex bg-[#B3C8CF] gap-3 justify-around items-center">
              {"XS S M L XL 2XL"}
            </p>
            <div className="flex justify-between p-2 items-center bg-[#B3C8CF]">
            <p className="bg-[#B3C8CF]">34.90 ₹</p>
            <p><FontAwesomeIcon icon={faShoppingCart}/></p>
            </div>
          
          </div>
          <div className="opacity-0 group-hover:opacity-100 transition-opacity duration-300 absolute -bottom-44 left-0 right-0 bg-[#A7C4BC] text-white p-2 mt-1 text-center">
            ZUM Warenkorb hinzufugenen
          </div>
        </Link>
      ))}
    </div>
    </>
  );
};

export default Suggested;
