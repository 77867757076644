import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState, useEffect } from "react";
import {
  faInstagram,
  faTiktok,
  faYoutube,
  faLinkedin,
} from "@fortawesome/free-brands-svg-icons";

const Footer = () => {
  const [isFirstFooterVisible, setFirstFooterVisible] = useState(false);

  const handleScroll = () => {
    const scrollTop = window.scrollY;
    const windowHeight = window.innerHeight;
    const secondFooterOffsetTop = document
      .querySelector("#second-footer")
      ?.getBoundingClientRect().top + scrollTop;

    if (scrollTop === 0) {
      setFirstFooterVisible(false); // Hide when at the very top
    } else if (scrollTop + windowHeight < secondFooterOffsetTop) {
      setFirstFooterVisible(true); // Show when not near second footer
    } else {
      setFirstFooterVisible(false); // Hide near second footer
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return (
    <footer id="footer" className="w-full relative matchfont text-white mt-4">
      {/* First Footer */}
     
  {/* First Footer */}
  {isFirstFooterVisible && (
       <div
       className="w-full footer2 text-center py-2 transition-transform duration-300 fixed bottom-0 left-0 flex justify-center items-center"
     >
       <h2 className="text-3xl font-bold logo absolute bottom-4 text-white">
         liquid 
       </h2>
     </div>
     
      )}


      {/* Second Footer */}
      <div
        id="second-footer"
        className="container mx-auto px-4 pt-12 pb-2 text-white"
      >
        <div className="container mx-auto px-4 pt-8">
          <div className="grid grid-cols-1 md:grid-cols-5 gap-4 px-14">
            {/* Logo & Social Media */}
            <div className="col-span-1">
              <h2 className="text-5xl font-bold logo mb-4">liquid</h2>
              <div className="flex space-x-3 mt-16">
                <a
                  href="#"
                  className="text-white hover:opacity-80 w-6 h-6 flex justify-center items-center rounded-full bg-[#9DB2BF]"
                >
                  <FontAwesomeIcon
                    icon={faInstagram}
                    className="text-sm text-[#264374]"
                  />
                </a>
                <a
                  href="#"
                  className="text-white hover:opacity-80 w-6 h-6 flex justify-center items-center rounded-full bg-[#9DB2BF]"
                >
                  <FontAwesomeIcon
                    icon={faTiktok}
                    className="text-sm text-[#264374]"
                  />
                </a>
                <a
                  href="#"
                  className="text-white hover:opacity-80 w-6 h-6 flex justify-center items-center rounded-full bg-[#9DB2BF]"
                >
                  <FontAwesomeIcon
                    icon={faYoutube}
                    className="text-sm text-[#264374]"
                  />
                </a>
                <a
                  href="#"
                  className="text-white hover:opacity-80 w-6 h-6 flex justify-center items-center rounded-full bg-[#9DB2BF]"
                >
                  <FontAwesomeIcon
                    icon={faLinkedin}
                    className="text-sm text-[#264374]"
                  />
                </a>
              </div>
            </div>
            {/* Menu Section */}
            <div className="col-span-1">
              <h4 className="underline underline-offset-8 mb-4">MENU</h4>
              <ul>
                <li className="hover:underline mt-2">Unisex</li>
                <li className="hover:underline mt-2">Damen</li>
                <li className="hover:underline mt-2">Herren</li>
                <li className="hover:underline mt-2">Kids & Teens</li>
                <li className="hover:underline mt-2">Wohnen</li>
              </ul>
            </div>
            {/* Help Section */}
            <div className="col-span-1">
              <h4 className="underline underline-offset-8 mb-4">HILFE</h4>
              <ul>
                <li className="hover:underline mt-2">Zahlung</li>
                <li className="hover:underline mt-2">Lieferung</li>
                <li className="hover:underline mt-2">Häufige Fragen</li>
              </ul>
            </div>
            {/* General Section */}
            <div className="col-span-1">
              <h4 className="underline underline-offset-8 mb-4">ANDERES</h4>
              <ul>
                <li className="hover:underline mt-2">Impressum</li>
                <li className="hover:underline mt-2">AGB</li>
                <li className="hover:underline mt-2">Datenschutz</li>
                <li className="hover:underline mt-2">Dateneinstellungen</li>
              </ul>
            </div>
            {/* Subscribe Section */}
            <div className="col-span-1">
              <h4 className="underline underline-offset-8 mb-4">ABONNIEREN</h4>
              <p className="text-xs mb-4">
                Erhalten Sie unsere aktuellen Informationen und 10% Rabatt auf
                lhre nächste Bestellung
              </p>
              <div className="flex">
                <input
                  type="email"
                  placeholder="Email"
                  className="p-2 bg-gray-100 text-black w-full"
                />
                <button className="p-2 ml-1 bg-[#9DB2BF] text-[#264374] hover:text-[#ffffff]">
                  SENDEN
                </button>
              </div>
            </div>
          </div>
          <h1 className="text-sm text-center text-white">
            Liquid <span>&#169;</span> 2025
          </h1>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
