import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Link } from "react-router-dom";

const Dashboard = () => {
  const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    arrows: true,
  };

  return (
    <>
      <div className="carousel-container">
        <Slider {...sliderSettings}>
          <div className="carousel-slide">
            <img
              src="https://img.freepik.com/free-vector/ecommerce-web-page-concept-illustration_114360-8204.jpg?ga=GA1.1.1342794970.1732615365&semt=ais_hybrid"
              alt="Slide 1"
              className="carousel-image object-contain"
            />
          </div>
          <div className="carousel-slide">
            <img
              src="https://img.freepik.com/free-vector/ecommerce-web-page-concept-illustration_114360-8204.jpg?ga=GA1.1.1342794970.1732615365&semt=ais_hybrid"
              alt="Slide 2"
              className="carousel-image object-contain"
            />
          </div>
          <div className="carousel-slide">
            <img
              src="https://img.freepik.com/free-vector/ecommerce-web-page-concept-illustration_114360-8204.jpg?ga=GA1.1.1342794970.1732615365&semt=ais_hybrid"
              alt="Slide 3"
              className="carousel-image object-contain"
            />
          </div>
        </Slider>
      </div>

      {/* Banner Section */}
      <div className="banner-container">
        <div className="banner-item">
          <img
            src="https://plus.unsplash.com/premium_photo-1684785618727-378a3a5e91c5?w=600&auto=format&fit=crop&q=6"
            alt="Banner 1"
            className="banner-image"
          />
        </div>
        <div className="banner-item relative">
          <img
            src="https://images.unsplash.com/photo-1544816155-12df9643f363?w=600&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTB8fGUlMjBjb21tZXJjZXxlbnwwfDF8MHx8fDA%3D"
            alt="Banner 2"
            className="banner-image"
          />
            <Link to="/products" className="bg-[#A7C4BC] text-white translate-1/2 px-8 py-2 absolute top-1/2">COLLECTION</Link>
        </div>
        <div className="banner-item">
          <img
            src="https://images.unsplash.com/photo-1731566665644-c255417fa06d?w=600&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTF8fGUlMjBjb21tZXJjZXxlbnwwfDF8MHx8fDA%3D"
            alt="Banner 3"
            className="banner-image"
          />
        </div>
      
      </div>
    </>
  );
};

export default Dashboard;
