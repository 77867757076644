import React, { useState, useEffect } from "react";
import logo from "../assets/logo.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSearch,
  faHeart,
  faShoppingCart,
  faUser,
  faLanguage,
} from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";

const Navbar = () => {
  const [activeMenu, setActiveMenu] = useState(null);
  const [showFirstNavbar, setShowFirstNavbar] = useState(true);
  const [showSecondNavbar, setShowSecondNavbar] = useState(true);
  const [lastScrollY, setLastScrollY] = useState(0);

  const handleMenuToggle = (menuName) => {
    setActiveMenu(activeMenu === menuName ? null : menuName);
  };

 
  const handleScroll = () => {
    const currentScrollY = window.scrollY;
    const atTop = currentScrollY === 0;
    const atBottom =
      window.innerHeight + currentScrollY >=
      document.documentElement.scrollHeight - 10;

    if (atTop) {
      // At the top, both navbars are visible.
      setShowFirstNavbar(true);
      setShowSecondNavbar(true);
    } else if (atBottom) {
      // At the bottom, both navbars are hidden.
      setShowFirstNavbar(false);
      setShowSecondNavbar(false);
    } else if (currentScrollY > lastScrollY) {
      // Scrolling down, hide the first navbar, keep the second one visible.
      setShowFirstNavbar(false);
      setShowSecondNavbar(true);
    } else {
      // Scrolling up, show the second navbar, hide the first one.
      setShowFirstNavbar(false);
      setShowSecondNavbar(true);
    }

    setLastScrollY(currentScrollY);
  };

  
  useEffect(() => {
    
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [lastScrollY]);
  

  
  
  
 
  return (
    <>
    { 
       (
        <>
        <div
        className={`bg-[#264374] matchfont text-white fixed top-0 w-full z-50 transition-transform duration-300`}
      >
      {showFirstNavbar  &&
         (
          <div className=" w-full relative py-3 px-4 flex justify-between items-center">
          <Link to="/" className="cursor-pointer">
            <img src={logo} alt="Liquid" className="w-12 h-12  object-contain" />
          </Link>
          
          <div className="cursor-pointer relative mb-2 -translate-x-1/2 cursor-pointer">
            <Link to="/" className="text-base absolute -top-2 left-1/2 logo lg:text-4xl text-center">liquid</Link>
          </div>
          
          <div className="space-x-4 hidden md:flex cursor-pointer">
            <button className="hover:text-gray-300">
              <FontAwesomeIcon icon={faSearch} className="text-base"/>
            </button>
            <button className="hover:text-gray-300">
              <FontAwesomeIcon icon={faHeart} className="text-base"/>
            </button>
            <button className="hover:text-gray-300">
              <FontAwesomeIcon icon={faShoppingCart} className="text-base"/>
            </button>
            <button className="hover:text-gray-300">
              <FontAwesomeIcon icon={faUser} className="text-base"/>
            </button>
            <button className="hover:text-gray-300">
              <p className="underline text-base">DE</p>
            </button>
          </div>

          <div className="md:hidden cursor-pointer">
            <button onClick={() => handleMenuToggle("MOBILE")}>☰</button>
          </div>
        </div>
        )
      }
      
      </div>

     {showSecondNavbar &&
        ( <div
        className={`bg-[#264374]  text-white fixed 
        }  w-full h-24 pt-1 z-40 shadow-md transition-transform duration-300 ${
           showFirstNavbar ? "top-[60px]" : "top-0"
        }`}
      >
        <div className={` mx-auto relative px-4 py-6 flex flex-wrap gap-2 text-xs lg:text-base  lg:gap-20 items-center justify-center md:justify-center`}>
          { (
            <div className={`cursor-pointer ${showFirstNavbar ? "hidden" : ""} text-base  logo lg:text-3xl text-center`}>
            liquid
            </div>
          )}
          <div className={`${showFirstNavbar ? "-translate-x-24" : ""} matchfont`}>
            {[
              "UNISEX",
              "DAMEN",
              "HERREN",
              "KIDS & TEENS",
              "WOHNEN",
            ].map((item) => (
              <span
                key={item}
                className={`${activeMenu === item ? "border-b border-b-2" : "" } cursor-pointer font-normal mx-2 text-sm`}
                onClick={() => handleMenuToggle(item)}
              >
                {item}
              </span>
            ))}
          </div>
          <div className="flex justify-start matchfont">
            {["NATURAL DYE", "ÜBER LIQUID"].map((item) => (
              <span
                key={item}
                className={`${activeMenu === item ? "border-b border-b-2" : "" } cursor-pointer mx-2 text-sm`}
                onClick={() => handleMenuToggle(item)}
              >
                {item}
              </span>
            ))}
          </div>
          {!showFirstNavbar  && (
            <div>
              <div className="space-x-4 cursor-pointer hidden md:flex">
                <button className="hover:text-gray-300">
                  <FontAwesomeIcon icon={faSearch} />
                </button>
                <button className="hover:text-gray-300">
                  <FontAwesomeIcon icon={faHeart} />
                </button>
                <button className="hover:text-gray-300">
                  <FontAwesomeIcon icon={faShoppingCart} />
                </button>
                <button className="hover:text-gray-300">
                  <FontAwesomeIcon icon={faUser} />
                </button>
                <button className="hover:text-gray-300 ">
                  <p className="underline">DE</p>
                </button>
              </div>
            </div>
          )}
        </div>

        
        {activeMenu && activeMenu !== "MOBILE" &&  (
          <div className="bg-[#f7f7f7] text-black w-full absolute top-18  py-4 border-t" >
            <div className="container mx-auto px-4 grid grid-cols-5 gap-4">
        
              <div>
                <h4 className="submatchfont mb-2 uppercase">New Arrivals</h4>
                <img
                  src="https://img.freepik.com/free-photo/portrait-handsome-confident-stylish-hipster-lambersexual-modelman-dressed-green-sweater-jeans-fashion-male-posing-studio-near-blue-wall_158538-24057.jpg?ga=GA1.1.676169200.1724844095&semt=ais_hybrid"
                  alt="New Arrivals"
                  className="w-36 h-36 mb-2 object-contain"
                />
                <p className="submatchfont uppercase">Special Offer</p>
             <div className="flex mt-2">
             <img
                  src="https://img.freepik.com/free-photo/portrait-fashionable-boy-posing_23-2148184595.jpg?ga=GA1.1.676169200.1724844095&semt=ais_hybrid"
                  alt="New Arrivals"
                  className="w-52 h-36 mb-2 object-contain"
                />
             <img
                  src="https://img.freepik.com/free-photo/portrait-young-man-yellow-scene_23-2148184808.jpg?ga=GA1.1.676169200.1724844095&semt=ais_hybrid"
                  alt="New Arrivals"
                  className="w-52 h-36 mb-2 object-contain"
                />
             </div>
              </div>
              <div>
                <h4 className="submatchfont mb-4 uppercase">Apparel</h4>
                <ul>
                  {[
                    "T-Shirts",
                    "Shirts",
                    "Hoodies / Sweatshirts",
                    "Hosen / Jogger & Shorts",
                    "Kleider / Rocke",
                  ].map((item) => (
                    <li key={item} className="submatchfontnew hover:text-gray-500">
                      {item}
                    </li>
                  ))}
                </ul>
              </div>
              <div>
                <h4 className="submatchfont mb-4 uppercase">Nichtwasche</h4>
                <ul>
                  {["Pyjamas", "Schlafshirts"].map((item) => (
                    <li key={item} className="submatchfontnew hover:text-gray-500">
                      {item}
                    </li>
                  ))}
                </ul>
              </div>
              <div>
                <h4 className="submatchfont mb-4 uppercase">Accessories</h4>
                <ul>
                  {["Socken", "Beanies", "Schals", "Taschen"].map((item) => (
                    <li key={item} className="submatchfontnew hover:text-gray-500">
                      {item}
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        )}
      </div>)
     }
     

     
      {activeMenu === "MOBILE" && (
        <div className="bg-[#264374] text-white md:hidden fixed inset-0 z-50">
          <ul className="p-6 space-y-4">
            {[
              "UNISEX",
              "DAMEN",
              "HERREN",
              "KIDS & TEENS",
              "WOHNEN",
              "NATURAL DYE",
              "ÜBER LIQUID",
            ].map((item) => (
              <li key={item}>
                <button
                  className="hover:text-gray-300"
                  onClick={() => handleMenuToggle(item)}
                >
                  {item}
                </button>
              </li>
            ))}
          </ul>
        </div>
      )}
</>
       )
    }
     
     


    </>
  );
};

export default Navbar;
