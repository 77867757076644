import React, { useState, useEffect } from "react";
import './App.css';
import Navbar from './Components/Navbar';
import Footer from './Components/Footer';
import Dashboard from "./Pages/Dashboard";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Products from "./Pages/Products";
import SingleProduct from "./Pages/SingleProduct";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import '@fortawesome/fontawesome-free/css/all.min.css';


function App() {
  const [homeScreenHeight, setHomeScreenHeight] = useState(window.innerHeight);

  // Function to handle window resize
  const handleResize = () => {
    setHomeScreenHeight(window.innerHeight);
  };

  // Set up event listener for window resize
  useEffect(() => {
    window.addEventListener("resize", handleResize);

    // Cleanup listener on unmount
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <>
     <Router>
     <Navbar />
     <div className="mt-40">
      <Routes>
        <Route path="/" element={<Dashboard />} />
        <Route path="/products" element={<Products />} />
        <Route path="/details" element={<SingleProduct />} />
      </Routes>
      </div>
      <Footer />
    </Router>
   
     

    
    
    </>
  );
}

export default App;
